import moment, { type MomentInput } from 'moment'

// Get the number of days until the due time, flooring the result
export const getDaysLeft = (dueAt: MomentInput) => -moment().diff(dueAt, 'days')

// Get the number of calendar days away from today
export const getDaysDiff = (dueDate: MomentInput) =>
  -moment().startOf('day').diff(dueDate, 'days')

// Get the start of the date the signature would be due
export const getDueDate = (daysLeft: number) =>
  moment().startOf('day').add(daysLeft, 'days').toDate()

// Get the time when the signature would be due (NOTE keep in sync with DB)
export const getDueAt = (dueDate: MomentInput) =>
  moment.utc(dueDate).add(29, 'hours').subtract(1, 'second').toDate()
