import get from 'lodash/get'
import isNil from 'lodash/isNil'
import omit from 'lodash/omit'
import omitBy from 'lodash/omitBy'
import pick from 'lodash/pick'
import reduce from 'lodash/reduce'
import { FontVariant } from './fonts'
import { SYSTEM_FONTS } from './settings'

export default class Font {
  constructor(setting, attr) {
    Object.assign(this, attr)
    this._setting = setting
  }

  static fromJSON({ type, ...attr }, setting) {
    attr = omitBy(attr, isNil)
    const FontType = FONT_TYPES.find((FontType) => {
      return FontType.prototype.type === type
    })
    if (FontType) return new FontType(setting, attr)
  }

  // Getters
  get type() {
    return this.constructor.type
  }

  get setting() {
    return this._setting
  }

  get familyStack() {
    return "'" + this.family + "', " + this.setting.fontFamily
  }

  get weightKeys() {
    return Object.keys(this.setting.weights)
  }

  get weights() {
    return reduce(
      this.setting.weights,
      (weights, value, key) => {
        weights[key] = this[key] || value
        return weights
      },
      {},
    )
  }

  // Clone the font
  clone() {
    const FontType = this.constructor
    return Object.assign(new FontType(), this)
  }

  // Convert the font to JSON
  toJSON() {
    return omit(this, '_font')
  }
}

export class CustomFont extends Font {
  get type() {
    return 'custom'
  }

  get family() {
    return get(this.font, 'family')
  }

  get font() {
    return this._font
  }

  get weights() {
    return { normal: 400 }
  }

  get url() {
    return `/fonts/${this.fontId}`
  }

  get isValid() {
    return !!this.fontId
  }

  set font(font) {
    this._font = font
    this.fontId = font ? font.id : undefined
  }

  toJSON() {
    return pick(this, 'type', 'fontId', this.weightKeys)
  }
}

export class GoogleFont extends Font {
  get type() {
    return 'google'
  }

  get variants() {
    return (
      this._variants ||
      FontVariant.expandWeights(this.weights, this.setting.italic)
    )
  }

  set variants(variants) {
    this._variants = variants
  }

  get isValid() {
    return !!this.family
  }

  toJSON() {
    const variants = this._variants
    const json = pick(this, 'type', 'family', this.weightKeys)
    if (variants) json.variants = variants
    return json
  }
}

export class SystemFont extends Font {
  get type() {
    return 'system'
  }

  get familyStack() {
    return SYSTEM_FONTS[this.family] || super.familyStack
  }

  get isValid() {
    return !!this.family
  }

  toJSON() {
    return pick(this, 'type', 'family', this.weightKeys)
  }
}

export const FONT_TYPES = [CustomFont, GoogleFont, SystemFont]
