export type FontDataModelInput = {
  filename: string
}

export class FontDataModel {
  id: number
  private _family: string
  private _filename: string

  static fromJSON(json: FontDataModelInput) {
    return Object.assign(new FontDataModel(), json)
  }

  static getFamily(filename: string) {
    const family = filename.split('.')[0]
    return family.replace(/\s+/g, '-')
  }

  get family() {
    return this._family
  }

  get filename() {
    return this._filename
  }

  set filename(filename: string) {
    this._filename = filename
    this._family = FontDataModel.getFamily(filename)
  }
}
