import { FontDataModel } from '@blissbook/lib/models'
import { immerable } from 'immer'
import some from 'lodash/some'
import Branding from './branding'
import HandbookSection from './handbook/section'
import HandbookSignatureRound from './handbook/signature-round'
import HandbookVersion from './handbook/version'
import { fromJSON, mapJSONValues } from './util/json'

export default class Handbook {
  static get patchKeys() {
    return ['acknowledgementForm', 'branding', 'contactFragments']
  }

  static get ATTRIBUTES() {
    return {
      branding: (v) => Branding.fromJSON(v),
      createdAt: (v) => fromJSON.date(v),
      fonts: (v) => v.map(FontDataModel.fromJSON),
      lastModifiedAt: (v) => fromJSON.date(v),
      lastPublishedAt: (v) => fromJSON.date(v),
      publishedAt: (v) => fromJSON.date(v),
      sections: (v, { id }) =>
        v.map((json) => HandbookSection.fromJSON({ handbookId: id, ...json })),
      signatureRounds: (v, { id }) =>
        v.map((json) =>
          HandbookSignatureRound.fromJSON({ handbookId: id, ...json }),
        ),
      updatedAt: (v) => fromJSON.date(v),
      versions: (v) => v.map(HandbookVersion.fromJSON),
    }
  }

  // Map attributes
  static mapJSON(json) {
    return mapJSONValues(json, Handbook.ATTRIBUTES)
  }

  // Create a handbook from JSON
  static fromJSON(json) {
    return Object.assign(new Handbook(), Handbook.mapJSON(json))
  }

  can(permissionId) {
    return this.permissionIds.includes(permissionId)
  }

  get isPublished() {
    return !!this.publishedAt
  }

  // Branding ------------------------------------------------------------------

  get branding() {
    return this._branding
  }

  get fonts() {
    return this._fonts
  }

  set branding(branding) {
    this._branding = branding
    this.loadBranding()
  }

  set fonts(fonts) {
    this._fonts = fonts
    this.loadBranding()
  }

  loadBranding() {
    const { branding, fonts } = this
    if (branding && fonts) {
      branding.loadCustomFonts(fonts)
    }
  }

  // URL ----------------------------------------------------------------------

  get path() {
    return `/handbooks/${this.id}`
  }

  // Sections -----------------------------------------------------------------

  // Determine if we have any variable content
  get hasVariableContent() {
    return some(this.sections, (section) => section.expression)
  }
}

Handbook[immerable] = true
