import flatMap from 'lodash/flatMap'
import sortBy from 'lodash/sortBy'

export class FontVariant {
  weight: FontWeight
  italic: boolean

  constructor(weight: FontWeight, italic: boolean) {
    this.weight = weight
    this.italic = italic
  }

  // Parse a string into a variant object
  static fromString(variant: string) {
    if (!variant) return

    // Special cases
    const regular = fontWeights.find((w) => w.value === 400)
    if (variant === 'regular') return new FontVariant(regular, false)
    if (variant === 'italic') return new FontVariant(regular, true)

    // General case
    const regEx = /(\d\d\d)(i*)/
    const match = variant.match(regEx)
    if (!match) return

    // Parse out
    const value = Number.parseInt(match[1], 10)
    const italic = match[2][0] === 'i'
    const weight = fontWeights.find((w) => w.value === value)
    if (weight) return new FontVariant(weight, italic)
  }

  // Determine the variants for this google font
  static expandWeights(weights: number[], italic?: boolean) {
    return flatMap(weights, (weight) => {
      const weights = [weight + '']
      if (italic) weights.push(weight + 'i')
      return weights
    })
  }

  // Convert a variant back to a string
  toString() {
    return this.weight.value + (this.italic ? 'i' : '')
  }
}

export type FontWeight = {
  value: number
  id: string
  label: string
}

export const fontWeights: FontWeight[] = [
  { value: 100, id: 'thin', label: 'Thin' },
  { value: 200, id: 'extraLight', label: 'Extra Light' },
  { value: 300, id: 'light', label: 'Light' },
  { value: 400, id: 'normal', label: 'Normal' },
  { value: 500, id: 'medium', label: 'Medium' },
  { value: 600, id: 'semiBold', label: 'Semi-Bold' },
  { value: 700, id: 'bold', label: 'Bold' },
  { value: 800, id: 'extraBold', label: 'Extra-Bold' },
  { value: 900, id: 'heavy', label: 'Heavy' },
]

export function findFontWeightByValue(value: number) {
  return fontWeights.find((weight) => weight.value === value)
}

export function findBestFontWeight(
  value: number,
  availableWeights = fontWeights,
) {
  const sortedWeights = sortBy(availableWeights, (weight) => {
    return Math.abs(weight.value - value)
  })
  return sortedWeights[0]
}
