const CSS_SELECTOR = '.blissbook-branded'
const CSS_BLOCK_REG_EX = '([^{]+)({[^}]+})'
const CSS_COMMENT_REG_EX = '/\\*[^*]+\\*/'
const CSS_MEDIA = '@media'

const KNOWN_SCOPES = {
  '@': undefined,
  [CSS_SELECTOR]: undefined,
  '.handbook-editor': undefined,
  '.acknowledge': 'body.blissbook .acknowledge',
  'nav.menu': 'body.blissbook nav.menu',
  'nav.toc': 'body.blissbook nav.toc',
  body: 'body.blissbook',
}

// Scope this css selector
function scopeSelector(selector) {
  // If no known scope, it's for handbook branding
  const scopeKey = Object.keys(KNOWN_SCOPES).find(
    (key) => selector.indexOf(key) === 0,
  )
  if (!scopeKey) return [CSS_SELECTOR, selector].join(' ')

  // Fix the scope
  const replaceKey = KNOWN_SCOPES[scopeKey]
  return !replaceKey ? selector : replaceKey + selector.slice(scopeKey.length)
}

// Group css together
function groupCss(css) {
  const groups = []

  // Pull @media groups out
  let startIndex = css.indexOf(CSS_MEDIA)
  while (startIndex !== -1) {
    // Look for open/close braces
    const openIndex = css.indexOf('{', startIndex + CSS_MEDIA.length)
    let index = openIndex + 1
    let openCount = 1
    while (openCount > 0 && index < css.length) {
      if (css[index] === '{') openCount += 1
      if (css[index] === '}') openCount -= 1
      if (openCount > 0) index += 1
    }

    // Add to groups
    groups.push({
      selector: css.substring(startIndex, openIndex - 1).trim(),
      css: css.substring(openIndex, index),
    })

    // Pull from css
    css = css.substring(0, startIndex) + css.substring(index + 1, css.length)
    startIndex = css.indexOf(CSS_MEDIA)
  }

  // Start with base group
  groups.unshift({
    selector: undefined,
    css: css,
  })

  return groups
}

// Apply scoping to the css
export function scopeCss(css) {
  // Remove comments
  const commentRegExp = new RegExp(CSS_COMMENT_REG_EX, 'g')
  css = css.replace(commentRegExp, '')

  const groups = groupCss(css)
  return groups
    .map((group) => {
      // Map the css into blocks
      const blockRegExp = new RegExp(CSS_BLOCK_REG_EX, 'g')
      const blocks = []
      let match
      // biome-ignore lint/suspicious/noAssignInExpressions: that's how you get multipe matches on the same string
      while ((match = blockRegExp.exec(group.css)) !== null) {
        const selectors = match[1]
          .replace(/\s+/g, ' ')
          .split(',')
          .map((selector) => selector.trim())
        const content = match[2].replace(/\s+/g, ' ').trim()
        blocks.push({ selectors, content })
      }

      // Map the blocks to lines
      const lines = blocks.map((block) => {
        const selectors = block.selectors.map(scopeSelector)
        return selectors.join(', ') + ' ' + block.content
      })

      // Complete the group
      if (!group.selector) return lines.join('\n')
      return (
        group.selector +
        ' {\n' +
        lines.map((line) => '  ' + line).join('\n') +
        '\n}'
      )
    })
    .join('\n')
}
