import pick from 'lodash/pick'

type GraphJSON = Record<string, any> & {
  keys?: string[]
}

// GraphQL does not support `undefined`, so pull out the `null` values
export function pickGraphJSON(json: GraphJSON): Record<string, any> {
  if (!json) return json

  const { keys } = json
  if (!keys) return json
  return pick(json, keys)
}
