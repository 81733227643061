import type { WhodunnitPerson } from '@blissbook/application/graph'
import {
  type Annotation,
  type BlissbookSection,
  type BlissbookSectionAttributes,
  type BlissbookSectionAttributesKey,
  type BlissbookSectionContent,
  type BlissbookSectionContentKey,
  type BlissbookSectionEntry,
  type BlissbookSectionEntryKey,
  getSectionChildTypes,
  sectionContentKeys,
  sectionEntryContentKeys,
  sectionSchemasByType,
} from '@blissbook/lib/blissbook'
import { type Node, renderText } from '@blissbook/lib/document'
import { formatTitle } from '@blissbook/lib/document/title'
import type { AudienceRootExpression } from '@blissbook/lib/expression'
import {
  type PersonalizationInput,
  personalizeContent,
} from '@blissbook/lib/personalization'
import { immerable } from 'immer'
import every from 'lodash/every'
import omitBy from 'lodash/omitBy'
import { pickGraphJSON } from '../util'
import type {
  HandbookSectionEntryTransaction,
  HandbookSectionTransaction,
} from './transaction'

type BlissbookSectionAttributesInput = BlissbookSectionAttributes & {
  keys?: string[]
}

type BlissbookSectionEntryInput = BlissbookSectionEntry & {
  keys?: string[]
}

function pickGraphSectionEntry(input: BlissbookSectionEntryInput) {
  return pickGraphJSON(input) as BlissbookSectionEntry
}

export type HandbookSectionDataModelInput = Partial<
  BlissbookSection & {
    attrs?: BlissbookSectionAttributesInput
    listEntries?: BlissbookSectionEntryInput[]
    sectionId?: number
    sectionVersion?: number
  }
>

export class HandbookSectionDataModel {
  handbookId: number
  id: number
  createdAt: Date
  createdBy: WhodunnitPerson
  dirtyFields?: string[]
  audienceExpression?: AudienceRootExpression | null
  expression?: string | null // Deprecated
  hidden: boolean
  hideToc: boolean
  languageCode?: string
  lastPublishedAt?: Date
  private _type: string
  private _theme?: string
  title?: string
  updatedAt: Date
  updatedBy: WhodunnitPerson
  version: number
  wysiwyg?: string

  private _attrs: BlissbookSectionAttributes = {}
  fragments: BlissbookSectionContent = {}
  listEntries: BlissbookSectionEntry[]

  editAnnotation?: string
  hideAnnotations: number[]
  viewAnnotation?: string
  viewAnnotationIds: string[]

  annotations: Annotation[]

  // Create a handbook section from JSON
  static fromJSON({
    id,
    listEntries,
    sectionId, // For handbook_toc_history table
    sectionVersion, // For handbook_toc_history table
    version,
    ...json
  }: HandbookSectionDataModelInput) {
    return new HandbookSectionDataModel({
      ...json,
      listEntries: listEntries
        ? listEntries.map(pickGraphSectionEntry)
        : undefined,
      id: sectionId || id,
      version: sectionVersion || version,
    })
  }

  constructor(json: Partial<BlissbookSection>) {
    Object.assign(this, json)
  }

  get attrs() {
    return this._attrs
  }

  get bookmark() {
    return `section-${this.id}`
  }

  get schema() {
    return sectionSchemasByType.get(this.type)
  }

  get theme() {
    return this._theme
  }

  get type() {
    return this._type
  }

  get childTypes() {
    return getSectionChildTypes(this)
  }

  get isDirty() {
    return this.dirtyFields && this.dirtyFields.length > 0
  }

  get isPublishable() {
    return !this.version || this.isDirty
  }

  get settings() {
    const { schema } = this
    return schema ? schema.getSettings(this.theme) : []
  }

  set attrs(input: BlissbookSectionAttributesInput) {
    const defaultAttrs = this.getDefaultAttrs()
    const attrs = pickGraphJSON(input)
    this._attrs = Object.create(defaultAttrs)
    Object.assign(this._attrs, attrs)
  }

  set type(type) {
    this._type = type
    this.attrs = this.attrs
  }

  set theme(themeId) {
    this._theme = themeId
    this.attrs = this.attrs
  }

  getDefaultAttrs() {
    const { schema } = this
    const themeId = this.theme
    const theme = this.getTheme()
    const attrs = schema ? schema.getDefaultValues(themeId) : {}
    return theme ? theme.getValues(attrs) : attrs
  }

  getTheme() {
    const { schema } = this
    const themeId = this.theme
    return schema?.themes?.find((theme) => theme.id === themeId)
  }

  isChildOf(parentSection?: HandbookSectionDataModel) {
    if (!parentSection) return false
    return parentSection.childTypes.includes(this.type)
  }

  toJSON() {
    const { attrs, type, theme, ...props } = this
    const json = omitBy(props, (_value, key) => key[0] === '_')
    return { attrs, type, theme, ...json }
  }

  // Title ------------------------------------------------------------------

  static NO_TITLE = '(no title)'

  static formatTitle(content: Node[]) {
    const title = renderText(content)
    return formatTitle(title)
  }

  static trimTitle(title: string | null) {
    if (title) title = title.trim() || null
    return title
  }

  static getContentTitle(section: Partial<BlissbookSection>) {
    const { title } = section.fragments
    if (!title) return
    return HandbookSectionDataModel.formatTitle(title)
  }

  static getTitle(section: Partial<BlissbookSection>) {
    return (
      HandbookSectionDataModel.trimTitle(section.title) ||
      HandbookSectionDataModel.getContentTitle(section)
    )
  }

  getContentTitle() {
    return HandbookSectionDataModel.getContentTitle(this)
  }

  getTitle() {
    return HandbookSectionDataModel.getTitle(this)
  }

  getDisplayTitle(noTitle = HandbookSectionDataModel.NO_TITLE) {
    return this.getTitle() || noTitle
  }

  // Content ------------------------------------------------------------------

  isEmpty() {
    const { schema } = this
    const keys = Object.keys(schema.content) as BlissbookSectionContentKey[]
    return (
      every(keys, (key) => !this.fragments[key]) &&
      every(this.listEntries, (entry) => {
        const keys = Object.keys(schema.entry) as BlissbookSectionEntryKey[]
        return every(keys, (key) => !entry[key])
      })
    )
  }

  getContentByGroup(group: string) {
    const settings = this.settings.filter((setting) => setting.group === group)
    return settings.reduce(
      (values, { key, groupKey }) => {
        const value = this.attrs[key as BlissbookSectionAttributesKey]
        if (value !== undefined) values[groupKey] = value
        return values
      },
      {} as Record<string, any>,
    )
  }

  get background() {
    return this.getContentByGroup('background')
  }

  // Personalization ----------------------------------------------------------

  personalizeContent(person: PersonalizationInput) {
    const { fragments } = this
    if (fragments) {
      for (const key of sectionContentKeys) {
        if (fragments[key]) personalizeContent(fragments[key], person)
      }
    }

    const { listEntries } = this
    if (listEntries) {
      listEntries.forEach((entry) => {
        for (const key of sectionEntryContentKeys) {
          if (entry[key]) personalizeContent(entry[key], person)
        }
      })
    }
  }

  // Annotations --------------------------------------------------------------

  get hasAnnotation() {
    return !!this.viewAnnotation || !!this.editAnnotation
  }

  // Transactions -------------------------------------------------------------

  applyTransaction(tr: HandbookSectionTransaction) {
    if ('entryUid' in tr) {
      this.applyEntryTransaction(tr)
    } else if (tr.type === 'clearDirty') {
      this.dirtyFields = undefined
    } else if (tr.type === 'setAttrs') {
      Object.assign(this.attrs, tr.value)
      Object.assign(this, tr.result)
    } else if (tr.type === 'setFragment') {
      this.fragments[tr.key] = tr.value
      Object.assign(this, tr.result)
    } else if (tr.type === 'setSettings') {
      Object.assign(this, tr.result)
    }
  }

  private applyEntryTransaction(tr: HandbookSectionEntryTransaction) {
    const entry = this.listEntries.find((entry) => entry.uid === tr.entryUid)
    if (tr.type === 'add') {
      const { sectionState } = tr.result
      const entry = pickGraphSectionEntry(tr.result.entry)
      this.listEntries.push(entry)
      Object.assign(this, sectionState)
    } else if (tr.type === 'move') {
      const { sectionState } = tr.result
      const index = this.listEntries.indexOf(entry)
      this.listEntries.splice(index, 1)
      this.listEntries.splice(tr.index, 0, entry)
      Object.assign(this, sectionState)
    } else if (tr.type === 'remove') {
      const { sectionState } = tr.result
      const index = this.listEntries.indexOf(entry)
      this.listEntries.splice(index, 1)
      Object.assign(this, sectionState)
    } else if (tr.type === 'setAttrs') {
      const { sectionState } = tr.result
      Object.assign(entry, tr.value)
      Object.assign(this, sectionState)
    } else if (tr.type === 'setFragment') {
      const { sectionState } = tr.result
      entry[tr.key] = tr.value
      Object.assign(this, sectionState)
    }
  }
}

// @ts-ignore: immerable
HandbookSectionDataModel[immerable] = true

export type HandbookSectionDataModelMap = Map<number, HandbookSectionDataModel>
