import flatMap from 'lodash/flatMap'
import groupBy from 'lodash/groupBy'
import uniq from 'lodash/uniq'
const rootFontUrl = 'https://fonts.googleapis.com/css?family='

// Group families
function groupByFamily(fonts) {
  const byFamily = groupBy(fonts, 'family')
  return Object.keys(byFamily).map((family) => {
    let variants = flatMap(byFamily[family], (f) => f.variants)
    variants = uniq(variants)
    variants.sort()
    return { family, variants }
  })
}

// Determine the google font url from this array of families/variants
export function formatGoogleUrl(fonts) {
  const byFamily = groupByFamily(fonts)
  if (!byFamily.length) return
  return (
    rootFontUrl +
    byFamily
      .map(
        (font) =>
          font.family.replace(/\s/g, '+') + ':' + font.variants.join(','),
      )
      .join('|')
  )
}
