import titleize from 'underscore.string/titleize'

export function formatTitle(title: string): string {
  // Titleize if all uppercase
  const isUpper = title === title.toUpperCase()
  if (isUpper) title = titleize(title)

  // Trim whitespace
  return title.replace(/\s+/g, ' ').trim()
}
