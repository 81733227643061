import { HandbookSectionDataModel, pickGraphJSON } from '@blissbook/lib/models'
import { immerable } from 'immer'
import { fromJSON, mapJSONValues } from '../util/json'

// biome-ignore lint/complexity/noStaticOnlyClass: requires some rework, for models it has to be classes
export class Entry {
  static get ATTRIBUTES() {
    return {
      createdAt: (v) => fromJSON.date(v),
      updatedAt: (v) => fromJSON.date(v),
    }
  }

  // Map attributes
  static mapJSON(json) {
    return mapJSONValues(json, Entry.ATTRIBUTES)
  }

  // Create a handbook section entry from JSON
  static fromJSON(json) {
    json = pickGraphJSON(json)
    return Object.assign(new Entry(), Entry.mapJSON(json))
  }
}

Entry[immerable] = true

// biome-ignore lint/complexity/noStaticOnlyClass: requires some rework, for models it has to be classes
export default class Section extends HandbookSectionDataModel {
  static get ATTRIBUTES() {
    return {
      createdAt: (v) => fromJSON.date(v),
      listEntries: (v) => v.map(Entry.fromJSON),
      fragments: pickGraphJSON,
      updatedAt: (v) => fromJSON.date(v),
      lastPublishedAt: (v) => fromJSON.date(v),
    }
  }

  static mapJSON(json) {
    return mapJSONValues(json, Section.ATTRIBUTES)
  }

  // Create a handbook section from JSON
  static fromJSON({
    id,
    listEntries,
    sectionId, // For handbook_toc_history table
    sectionVersion, // For handbook_toc_history table
    version,
    ...json
  }) {
    json = Section.mapJSON({
      ...json,
      id: sectionId || id,
      version: sectionVersion || version,
    })

    if (listEntries) {
      json.listEntries = listEntries.map((j) => Entry.fromJSON(j, Section))
    }

    return new Section(json)
  }
}

Section[immerable] = true
